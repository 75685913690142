import { HookFormTextInput } from '@/components/FormTextInput/HookFormTextInput';
import { HookFormTextArea } from '@/components/FormTextInput/HookFormTextArea';
import { PricingComponent } from '@/components';
import {
  useForm,
  Controller,
  UseFormSetValue,
  UseFormWatch,
  Control,
} from 'react-hook-form';
import { convertToLocalDate } from '@/utils';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Listing, ProductTypes } from '@/types';
import {
  Box,
  MessageBanner,
  Typography,
  DesktopTimePicker,
  FormControl,
  DatePicker,
  useTheme,
  InputField,
} from '@silverstein-properties/inspirelabs-ui';
import { StyledForm } from './EditListing.styles';
import { formatDateShortForm } from '@/utils/';
import { useFeatureFlags, useMerchantPlatformFee } from '@/hooks';
import {
  EditListingInput,
  PricingInput,
  RepeatDaysModalInput,
} from '@/classes';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  determineTimeFrameMessage,
  formatRecurrenceModalString,
  formatRecurringDaysString,
  formatTimeIntervalString,
} from '@/utils/recurrenceUtils';
import { RepeatOption } from '../AddListing';
import { SetRepeatDaysModal } from '../SetRepeatDaysModal';
import { RecurringEvent } from '@/types/RecurringEvent';
import { set } from 'date-fns';
import {
  TIME_INTERVALS,
  TIME_INTERVALS_BETWEEN,
} from '@/constants/recurringEvent';
import {
  StyledBox,
  StyledBoxFormControlContainer,
  StyledFormHelperText,
} from '../AddListing/AddListing.styles';

export type EditListingPropsType = {
  listingError: string | null;
  handleSubmit: (data: EditListingInput) => void;
  listing: Listing;
  recurringEvent?: RecurringEvent;
  experienceLength?: number;
  editRecurringSeries: boolean;
  experienceType?: ProductTypes;
  setChangesInputted: (value: React.SetStateAction<boolean>) => void;
};

export enum EditRecurringEvent {
  isEventOnly = 'isEventOnly',
  isRecurringEvent = 'isRecurringEvent',
}

export const selectionDialogProp = [
  {
    label: 'This event only',
    value: EditRecurringEvent.isEventOnly,
  },
  {
    label: 'This and following events in series',
    value: EditRecurringEvent.isRecurringEvent,
  },
];

export const EditListing = ({
  listingError,
  handleSubmit,
  listing,
  recurringEvent,
  experienceLength = 0,
  editRecurringSeries,
  experienceType,
  setChangesInputted,
}: EditListingPropsType) => {
  const resolver = classValidatorResolver(EditListingInput);
  const theme = useTheme();
  const { isEditLocationEnabled, isRecurringEventsEnabled } = useFeatureFlags();
  const { data: platformFeeRaw } = useMerchantPlatformFee(experienceType);
  const listingBooking = listing.hasBookings;
  const {
    register,
    control,
    formState,
    handleSubmit: useFormHandleSubmit,
    setValue,
    getValues,
    watch,
    setError,
  } = useForm<EditListingInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      date: formatDateShortForm(listing.startsAt) || '',
      totalAmount: listing.basePrice?.totalAmount || listing.totalAmount || '',
      taxRateId: listing.basePrice?.taxRateId || '',
      priceType: listing.basePrice?.priceType || '',
      priceId: listing.basePriceId,
      ...(experienceType === ProductTypes.ONE_TIME_EVENT && {
        description: listing.meetingPoint?.description || '',
        address: listing.meetingPoint?.address?.street || '',
        apt: listing.meetingPoint?.address?.apartment || '',
        city: listing.meetingPoint?.address?.city || '',
        state: listing.meetingPoint?.address?.state || '',
        zip: listing.meetingPoint?.address?.zipCode || '',
      }),
      startTime: listing.startsAt,
      endTime: editRecurringSeries
        ? set(listing.startsAt, {
            hours: recurringEvent?.endDateTime.getHours(),
            minutes: recurringEvent?.endDateTime.getMinutes(),
          })
        : listing.endsAt,
      repeatDays: {
        selectedDays: recurringEvent?.repeatDays?.daysOfWeek || undefined,
        repeatDaysType: recurringEvent?.repeatDays?.type || undefined,
        repeatDaysInterval: recurringEvent?.repeatDays?.interval || undefined,
        until: recurringEvent?.repeatDays?.until || undefined,
      },
      timeInterval: {
        intervalSegment:
          recurringEvent?.timeInterval?.intervalSegment || undefined,
        timeBetweenInterval:
          recurringEvent?.timeInterval?.timeBetweenInterval || undefined,
      },
      editRecurringSeries: editRecurringSeries,
      isEditLocationEnabled,
      productType: experienceType,
    },
  });
  const { dirtyFields, errors, isDirty } = formState;

  const [repeat, setRepeat] = useState(
    !!recurringEvent &&
      recurringEvent?.repeatDays?.daysOfWeek &&
      recurringEvent?.repeatDays?.until
      ? RepeatOption.REPEAT
      : RepeatOption.NONE
  );
  const [recurrenceDisplayString, setRecurrenceDisplayString] =
    useState<string>(
      !!recurringEvent &&
        recurringEvent?.repeatDays?.daysOfWeek &&
        recurringEvent?.repeatDays?.until
        ? formatRecurrenceModalString(
            recurringEvent?.repeatDays?.daysOfWeek,
            recurringEvent?.repeatDays?.until
          )
        : 'Set reoccurrence'
    );
  const [modalOpen, setModalOpen] = useState(false);
  const [timeInterval, setTimeInterval] = useState(
    getValues('timeInterval.intervalSegment') || 0
  );
  const [timeBetweenInterval, setTimeBetweenInterval] = useState(
    getValues('timeInterval.timeBetweenInterval') || 0
  );
  const [timeFrameMessage, setTimeFrameMessage] = useState<{
    message: string;
    error: boolean;
  }>({ message: '', error: false });

  useEffect(() => {
    setChangesInputted(isDirty);
  }, [isDirty]);

  const handleTimeFrameMessage = () => {
    const startTime = getValues('startTime');
    const endTime = getValues('endTime');
    const timeInterval = getValues('timeInterval');

    setTimeFrameMessage(
      determineTimeFrameMessage(startTime, endTime, timeInterval)
    );
  };

  const setNewEndTime = (newlySelectedDateTime: Date) => {
    const newEndTime = new Date(
      newlySelectedDateTime.getTime() + experienceLength * 60000
    );
    if (newEndTime.getDay() === newlySelectedDateTime.getDay()) {
      setValue('endTime', newEndTime);
    }
  };

  const onChangeStartTime = (newStartTime: Date) => {
    setNewEndTime(newStartTime);
    handleTimeFrameMessage();
  };

  const onChangeEndTime = () => {
    handleTimeFrameMessage();
  };

  const handleRepeatDaysOptionChange = (data: RepeatDaysModalInput) => {
    const displayRecurrence = formatRecurrenceModalString(
      data.selectedDays,
      data.until
    );
    setValue('repeatDays', data);
    setChangesInputted(true);
    setRecurrenceDisplayString(displayRecurrence);
  };

  const handleTimeInterval = (intervalSegment: number) => {
    const timeBetweenInterval = getValues('timeInterval.timeBetweenInterval');
    setValue('timeInterval', {
      intervalSegment,
      timeBetweenInterval: timeBetweenInterval || 0,
    });
    setTimeInterval(intervalSegment);
    setChangesInputted(true);
    handleTimeFrameMessage();
  };

  const handleTimeBetweenInterval = (timeBetweenInterval: number) => {
    const intervalSegment = getValues('timeInterval.intervalSegment');
    setValue('timeInterval', {
      intervalSegment: intervalSegment || 0,
      timeBetweenInterval,
    });
    setTimeBetweenInterval(timeBetweenInterval);
    setChangesInputted(true);
    handleTimeFrameMessage();
  };

  // TODO: We need to make the error message more dynamic - grab them from backend
  return (
    <>
      {!!listingError && (
        <Box sx={{ mb: 2 }}>
          <MessageBanner type="error">{listingError}</MessageBanner>
        </Box>
      )}
      {modalOpen && (
        <SetRepeatDaysModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleRepeatDaysOptionChange={handleRepeatDaysOptionChange}
          defaultRepeatDays={recurringEvent?.repeatDays}
          currentDate={listing.startsAt}
        />
      )}
      <StyledForm
        onSubmit={useFormHandleSubmit(handleSubmit)}
        id="editListingForm"
      >
        <Typography variant="h5">{listing.title}</Typography>

        <Typography variant="subtitle1">Date</Typography>
        <>
          <FormControl>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Date"
                  disabled={listingBooking}
                  value={convertToLocalDate(field.value)} // Convert the date string to a local date object. DatePicker will display the date in the local time zone
                  onChange={newValue => {
                    field.onChange(formatDateShortForm(newValue as Date)); // Convert the local date object back to a date string (e.g. 2024-03-30) and store it in the form state
                  }}
                  onError={(error, value) =>
                    setError('date', {
                      message: `${error}: ${value}`,
                    })
                  }
                />
              )}
            />
            <FormHelperText error={!!errors.date}>
              {!!errors.date ? errors.date.message : ''}
            </FormHelperText>
          </FormControl>
          {!editRecurringSeries && recurringEvent?.repeatDays?.daysOfWeek ? (
            <Typography sx={{ color: theme.palette.primary.medium }}>
              {formatRecurringDaysString(
                recurringEvent.repeatDays.type || '',
                recurringEvent.repeatDays.until
              )}
            </Typography>
          ) : (
            ''
          )}
        </>

        {isRecurringEventsEnabled && editRecurringSeries && (
          <InputField
            select
            label="Repeat"
            value={repeat}
            dropdownItems={[
              { label: 'Does not repeat', value: RepeatOption.NONE },
              {
                label: recurrenceDisplayString,
                value: RepeatOption.REPEAT,
              },
            ]}
            onChange={e => {
              setRepeat(e.target.value);
              setChangesInputted(true);
              if (e.target.value === RepeatOption.REPEAT) {
                setModalOpen(true);
              }
            }}
            sx={{ marginBottom: theme.spacers.l }}
          />
        )}

        <Typography variant="subtitle1">Time</Typography>
        <>
          <StyledBox>
            <StyledBoxFormControlContainer>
              <FormControl>
                <Controller
                  name="startTime"
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <DesktopTimePicker
                      label="From"
                      {...field}
                      onChange={val => {
                        onChange(val);
                        onChangeStartTime(val);
                      }}
                    />
                  )}
                />
                <FormHelperText error={!!errors.startTime}>
                  {!!errors.startTime ? errors.startTime.message : ''}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <Controller
                  name="endTime"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Please enter a time' },
                  }}
                  render={({ field: { onChange, ...field } }) => (
                    <DesktopTimePicker
                      label="To"
                      {...field}
                      onChange={val => {
                        onChange(val);
                        onChangeEndTime();
                      }}
                    />
                  )}
                />
                <FormHelperText error={!!errors.endTime}>
                  {!!errors.endTime ? errors.endTime.message : ''}
                </FormHelperText>
              </FormControl>
            </StyledBoxFormControlContainer>
            {editRecurringSeries && timeFrameMessage.message && (
              <StyledFormHelperText error={timeFrameMessage.error}>
                {timeFrameMessage.message}
              </StyledFormHelperText>
            )}
          </StyledBox>
          {isRecurringEventsEnabled &&
          !editRecurringSeries &&
          recurringEvent?.timeInterval?.intervalSegment ? (
            <Typography sx={{ color: theme.palette.primary.medium }}>
              {formatTimeIntervalString(
                recurringEvent.timeInterval,
                recurringEvent.startDateTime,
                recurringEvent.endDateTime
              )}
            </Typography>
          ) : (
            ''
          )}
        </>

        {/* time interval */}
        {isRecurringEventsEnabled && editRecurringSeries && (
          <StyledBox>
            <StyledBoxFormControlContainer>
              <FormControl fullWidth>
                <InputField
                  select
                  label="Intervals"
                  value={timeInterval}
                  dropdownItems={TIME_INTERVALS}
                  onChange={e => {
                    handleTimeInterval(e.target.value);
                  }}
                  sx={{ marginBottom: theme.spacers.s }}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputField
                  select
                  label="Time Between Intervals"
                  value={timeBetweenInterval}
                  dropdownItems={TIME_INTERVALS_BETWEEN}
                  onChange={e => {
                    handleTimeBetweenInterval(e.target.value);
                  }}
                  sx={{ marginBottom: theme.spacers.s }}
                />
              </FormControl>
            </StyledBoxFormControlContainer>
            <StyledFormHelperText error={false}>
              This event repeats at this interval within the time frame
            </StyledFormHelperText>
          </StyledBox>
        )}
        {experienceType !== ProductTypes.FOOD_SERVICE && (
          <PricingComponent
            hideTitle={true}
            hidePaymentMethods={true}
            platformFeeRaw={platformFeeRaw}
            formState={formState}
            control={control as unknown as Control<PricingInput>}
            setValue={setValue as unknown as UseFormSetValue<PricingInput>}
            watch={watch as unknown as UseFormWatch<PricingInput>}
            productType={experienceType}
          />
        )}
        {isEditLocationEnabled &&
        experienceType === ProductTypes.ONE_TIME_EVENT ? (
          <>
            <Typography variant="subtitle1">
              Where should your guests meet you?
            </Typography>
            <span className="inline-group" style={{ overflow: 'hidden' }}>
              <HookFormTextInput
                type="text"
                label="Address"
                width="form-l"
                isFilled={
                  !!listing.meetingPoint?.address?.street ||
                  !!Object.keys(dirtyFields).includes('address')
                }
                name="address"
                register={register}
                error={errors.address}
              />
              <HookFormTextInput
                isFilled={
                  !!listing.meetingPoint?.address?.apartment ||
                  !!Object.keys(dirtyFields).includes('apt')
                }
                register={register}
                name="apt"
                label="Apt./Fl."
                type="text"
                width="form-s"
                rounded="rounded-right"
              />
            </span>
            <span className="inline-group" style={{ overflow: 'hidden' }}>
              <HookFormTextInput
                isFilled={
                  !!listing.meetingPoint?.address?.city ||
                  !!Object.keys(dirtyFields).includes('city')
                }
                register={register}
                name="city"
                label="City"
                type="text"
                width="form-m"
                rounded="rounded-left"
                error={errors.city}
              />
              <HookFormTextInput
                isFilled={
                  !!listing.meetingPoint?.address?.state ||
                  !!Object.keys(dirtyFields).includes('state')
                }
                register={register}
                name="state"
                label="State"
                type="text"
                width="form-s"
                rounded="square-input"
                error={errors.state}
              />

              <HookFormTextInput
                isFilled={
                  !!listing.meetingPoint?.address?.zipCode ||
                  !!Object.keys(dirtyFields).includes('zip')
                }
                register={register}
                name="zip"
                label="ZipCode"
                type="text"
                width="form-s"
                rounded="rounded-right"
                error={errors.zip}
              />
            </span>
            <Typography variant="subtitle1">
              Describe where you'll meet
            </Typography>
            <HookFormTextArea
              size=""
              label="Meeting point description"
              limit={TEXT_AREA_LIMIT_LONG}
              name="description"
              isFilled={
                !!listing.description ||
                !!Object.keys(dirtyFields).includes('description')
              }
              register={register}
              error={errors.description}
            />
          </>
        ) : null}
      </StyledForm>
    </>
  );
};
