import {
  Card,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
  Svg,
} from '@silverstein-properties/inspirelabs-ui';
import { StyledCardContent } from './PlanDetailSection.styles';
import { formatDateForExperienceView } from '@/utils';
import { ReactNode, useState } from 'react';
import { useFeatureFlags } from '@/hooks';
import {
  MembershipPlan,
  MembershipPlanPublishStatus,
} from '@/types/MerchantMembership';
import {
  convertPriceAndBillingToString,
  renderContractLength,
} from '@/utils/membershipUtils';
import AlertDialog from '@/components/AlertDialog/AlertDialog';
import AlertIcon from '@/components/AlertDialog/AlertIcon.svg';
import { ActionsType } from '@/hooks/useMembershipEdit';
import { Divider } from '@mui/material';

export type PlanDetailSectionPropsType = {
  children: ReactNode;
  id: string;
  title: string;
  isLastPlan: boolean;
  membershipPlan: MembershipPlan;
  onActionsClick: (actionType: ActionsType, id: string) => void;
  showDeletePlanAlertDialog: boolean;
  handleDeletePlansAlertDialogClose: (value: boolean) => Promise<void>;
};

export const PlanDetailSection = ({
  children,
  id,
  title,
  membershipPlan,
  isLastPlan,
  onActionsClick,
  showDeletePlanAlertDialog,
  handleDeletePlansAlertDialogClose,
}: PlanDetailSectionPropsType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isOpen = Boolean(anchorEl);
  const { isEditButtonInMembershipDetailsPageEnabled } = useFeatureFlags();

  const formattedTimeStamp = membershipPlan.updatedAt
    ? 'Updated at ' + formatDateForExperienceView(membershipPlan.updatedAt)
    : membershipPlan.createdAt
    ? 'Created at ' + formatDateForExperienceView(membershipPlan.createdAt)
    : '';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPlanPublished =
    membershipPlan.publishStatus === MembershipPlanPublishStatus.PUBLISHED;

  //  terminate plan:  only when there are active users with no expiration date
  const canPlanTerminate =
    membershipPlan?.membersCount?.totalActivePlans >
    membershipPlan?.membersCount?.activeExpiringPlans;

  // delete plan: only with no transaction history (un-published and inactive)
  const canPlanDelete =
    !isPlanPublished &&
    membershipPlan?.membersCount?.totalActivePlans === 0 &&
    !membershipPlan?.hasPurchaseHistory;

  return (
    <Grid id={id} item xs={12}>
      {showDeletePlanAlertDialog && (
        <AlertDialog
          open={showDeletePlanAlertDialog}
          onClose={handleDeletePlansAlertDialogClose}
          image={AlertIcon}
          title="Are you sure you want to delete this plan?"
          message="Selecting the button 'Confirm' will permanently delete this plan"
          agree="Confirm"
          disagree="Cancel"
        />
      )}
      <Card variant="outlined">
        <StyledCardContent>
          <Stack spacing={4}>
            <Grid container>
              <Stack spacing={theme.spacers.xxs}>
                <Typography variant="h5">{title}</Typography>
                <Typography variant="body3" color="primary.medium">
                  {formattedTimeStamp}
                </Typography>
                <Stack
                  direction="row"
                  spacing={theme.spacers.s}
                  divider={
                    <Divider
                      orientation="vertical"
                      sx={{ backgroundColor: 'grey.400' }}
                    />
                  }
                >
                  <Typography
                    variant="body3"
                    color={
                      membershipPlan.hasMembers
                        ? 'success.main'
                        : 'primary.medium'
                    }
                  >
                    {membershipPlan.hasMembers ? 'Active' : 'Inactive'}
                  </Typography>
                  <Typography
                    variant="body3"
                    color={isPlanPublished ? 'success.main' : 'primary.medium'}
                  >
                    {isPlanPublished ? 'Published' : 'Unpublished'}
                  </Typography>
                </Stack>
              </Stack>
              {isEditButtonInMembershipDetailsPageEnabled ? (
                <Box sx={{ marginLeft: 'auto' }}>
                  <Button size="small" variant="outlined" onClick={handleClick}>
                    Actions
                  </Button>
                  <Menu
                    onClose={handleClose}
                    onClick={handleClose}
                    open={isOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{ mt: 1 }}
                  >
                    <MenuItem
                      onClick={() => {
                        onActionsClick(ActionsType.EDIT_PLAN, id);
                      }}
                    >
                      Edit Plan
                    </MenuItem>
                    <MenuItem
                      data-testid="publishUnpublishPlan"
                      onClick={() => {
                        onActionsClick(
                          isPlanPublished
                            ? ActionsType.UNPUBLISH_PLAN
                            : ActionsType.PUBLISH_PLAN,
                          id
                        );
                      }}
                    >
                      {isPlanPublished ? 'Un-publish Plan' : 'Publish Plan'}
                    </MenuItem>
                    {canPlanTerminate ? (
                      <MenuItem
                        onClick={() => {
                          onActionsClick(ActionsType.TERMINATED_PLAN, id);
                        }}
                      >
                        Terminate Plan
                      </MenuItem>
                    ) : null}

                    {canPlanDelete ? (
                      <MenuItem
                        onClick={() => {
                          onActionsClick(ActionsType.DELETE_PLAN, id);
                        }}
                      >
                        Delete Plan
                      </MenuItem>
                    ) : null}
                  </Menu>
                </Box>
              ) : null}
            </Grid>
            <Grid container>
              <Box>
                <Typography variant="subtitle1">
                  {convertPriceAndBillingToString(
                    membershipPlan.totalAmount || 0,
                    membershipPlan.billingCycleType
                  )}
                </Typography>
                <Typography variant="body1">
                  {membershipPlan.maxNbOfConsumers}-member
                </Typography>
                <Typography variant="body2">
                  {renderContractLength(membershipPlan)}
                </Typography>
                <Typography
                  variant="body2"
                  color={theme.palette.primary.medium}
                >
                  {membershipPlan.autoRenew ? 'Auto-renew' : null}
                </Typography>
              </Box>
            </Grid>
          </Stack>
          <Box sx={{ mt: 4 }}>{children}</Box>
        </StyledCardContent>
      </Card>
      {isLastPlan && (
        <Button
          type="submit"
          variant="text"
          sx={{ marginRight: theme.spacers.l }}
          startIcon={<Svg src="/images/plus.svg" width={24} height={24} />}
          onClick={() => {
            onActionsClick(ActionsType.ADD_PLAN, id);
          }}
        >
          New Plan
        </Button>
      )}
    </Grid>
  );
};
