import {
  styled,
  Dialog,
  DialogActions,
} from '@silverstein-properties/inspirelabs-ui';

export const DialogBox = styled(Dialog)({
  textAlign: 'center',
  alignItems: 'center',
});

export const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(5),
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(5),
  gap: theme.spacers.l,
  '& > button:not(:first-of-type)': {
    marginLeft: 0,
  },
}));
