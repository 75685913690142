import { ScreensViews } from '@/hooks/useMembershipEdit';

export const MEMBERSHIP_DETAIL_MENUS = [
  {
    id: 'aboutMembershipDetails',
    title: 'Membership Details',
    type: 'group',
    children: [
      {
        id: ScreensViews.TITLE_DESCRIPTION,
        image: '/images/sections/about.svg',
        title: 'Title & description',
        button: 'Edit',
        withDetail: true,
        showTimestamp: true,
      },
      {
        id: ScreensViews.PHOTOS,
        image: '/images/sections/photo.svg',
        title: 'Photos',
        button: 'Edit',
        withDetail: true,
        showTimestamp: true,
      },
    ],
  },
  {
    id: 'aboutManage',
    title: 'Manage',
    type: 'group',
    children: [
      {
        id: ScreensViews.PLANS,
        image: '/images/sections/credits.svg',
        title: 'Plans',
        button: 'Edit',
        withDetail: false,
        showTimestamp: true,
      },
      {
        id: ScreensViews.MEMBERS,
        image: '/images/sections/community.svg',
        title: 'Members',
        button: 'Actions',
        withDetail: true,
        showTimestamp: false,
      },
    ],
  },
];

export const EDIT_PLAN_ALERT_DIALOGS: {
  [key: string]: {
    message: string;
    agree: string;
    disagree: string;
  };
} = {
  edit: {
    message:
      'You are about to make changes to core aspects of this plan. Are you sure you want to continue?',
    agree: 'Continue',
    disagree: 'Cancel',
  },
  confirm: {
    message: '',
    agree: 'Continue',
    disagree: 'Go Back',
  },
};

export const EDIT_PLAN_CORE_FIELDS = [
  'membershipPlanPrice',
  'taxRateId',
  'memberQuantity',
  'contractType',
  'contractLength',
  'billingCycle',
];
