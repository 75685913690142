import AlertIcon from './AlertIcon.svg';
import {
  DialogBox,
  StyledDialogActions,
  StyledImage,
} from './AlertDialog.styles';
import {
  Button,
  DialogContent,
  DialogTitle,
  MessageBanner,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { Box } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: boolean) => Promise<void> | void;
  title: string;
  image?: string | JSX.Element;
  message: string | JSX.Element;
  errorMessage?: string;
  agree: string;
  disagree: string;
  width?: number;
  isDisagreePrimary?: boolean;
}

export default function AlertDialog({
  open,
  onClose,
  title,
  image,
  message,
  errorMessage,
  agree,
  disagree,
  width = 400,
  isDisagreePrimary = false,
}: SimpleDialogProps) {
  const [isLoadingButton, setLoadingButton] = useState(false);

  const handleUserConfirmation = async () => {
    setLoadingButton(true);
    try {
      await onClose(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const agreeButton = (
    <LoadingButton
      loading={isLoadingButton}
      variant={isDisagreePrimary ? 'text' : 'contained'}
      size="large"
      onClick={() => handleUserConfirmation()}
      key="agreeBtn"
    >
      {agree}
    </LoadingButton>
  );

  const disagreeButton = (
    <Button
      variant={isDisagreePrimary ? 'contained' : 'text'}
      size="large"
      onClick={() => onClose(false)}
      key="disagreeBtn"
    >
      {disagree}
    </Button>
  );

  const imageComponent =
    typeof image === 'string' || !image ? (
      <StyledImage
        height="80px"
        width="80px"
        src={image || AlertIcon}
        alt="Alert Icon"
      />
    ) : (
      <Stack
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        sx={{
          width: '80px',
          height: '80px',
        }}
      >
        {image}
      </Stack>
    );

  return (
    <DialogBox
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: width,
          maxHeight: '80%',
          height: 'auto',
        },
      }}
    >
      {imageComponent}
      <DialogTitle component={Stack} id="alert-dialog-title">
        <Typography variant="h4">{title}</Typography>
        <Box sx={{ width: '100%' }}>
          <MessageBanner hidden={!errorMessage} type="error">
            {errorMessage}
          </MessageBanner>
        </Box>
      </DialogTitle>
      <DialogContent aria-describedby="alert-dialog-description">
        {typeof message === 'string' ? (
          <Typography color="primary" variant="subtitle1">
            {message}
          </Typography>
        ) : (
          message
        )}
      </DialogContent>
      <StyledDialogActions>
        {(isDisagreePrimary
          ? [disagreeButton, agreeButton]
          : [agreeButton, disagreeButton]
        ).map(button => button)}
      </StyledDialogActions>
    </DialogBox>
  );
}
